import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuestGuard } from './guards/guest.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren:  () => import('./components/public/login/login.module').then(m => m.LoginModule),
    canActivate: [GuestGuard]
  },

  {
    path: 'private',
    loadChildren: () => import('./components/private/private.module').then( m => m.PrivateModule )
  },

  { path: '**', pathMatch: 'full', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
