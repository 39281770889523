import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {GeneralService} from './general.service';
import {Login} from '../interfaces/interfaces';
import {map, catchError} from 'rxjs/operators';
import {empty, of, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {UserLogged} from "../interfaces/user-config.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends GeneralService {
  optionChanged: EventEmitter<string> = new EventEmitter<string>();
  optionSelected: string;
  userToken: string;
  userLogged: UserLogged = {ots: []};
  loadingUserLogged: boolean;

  constructor(private http: HttpClient,
              private router: Router) {
    super();
    this.leerToken();
    /*
    if ( this.userToken.length > 10 ){
      this.getUserLogged(true).subscribe();
    }
     */
  }
  set optionData(val: any) {
    this.optionSelected = val;
    this.optionChanged.emit(this.optionSelected);
  }
  login(login: Login): Observable<any> {
    let params = {};
    environment.domain
      ? params = {
        ...login,
        client: environment.domain,
      }
      : params = {
        ...login,
      };
    return this.http.post(this.builder('auth/login'), params)
      .pipe(
        map((response: any) => {
          if (response.user.mandante_projects_fleet === null || response.user.mandante_projects_fleet === undefined) {
            response.user.mandante_projects_fleet = [];
          }
          if (response.user.status === 0) {
            return response;
          }
          if (response.user.status === 1) {
            this.guardarToken(response);
            return response;
          }
        })
      );
  }

  logout(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.userToken}`
    });

    return this.http.post(this.builder('auth/logout'), {}, {headers})
      .pipe(
        map((response: any) => {
          this.borrarToken();
          this.router.navigateByUrl('/login');
          return response;
        }), catchError((err: any) => {
          this.borrarToken();
          this.router.navigateByUrl('/login');
          return err;
        })
      );
  }

  private leerToken(): void {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
  }

  private guardarToken(response: any): void {
    this.userToken = response.access_token;
    this.userLogged = response.user;
    localStorage.setItem('token', response.access_token);
  }

  private borrarToken(): void {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      this.userToken = '';
      this.userLogged = null;
    }
  }

  loggedIn(): boolean {
    return this.userToken.length >= 10;
  }

  getUserLogged(reload: boolean = false): Observable<any> {
    if (this.userLogged === null || reload) {
      this.loadingUserLogged = true;
      const headers = new HttpHeaders({
        Authorization: `Bearer ${this.userToken}`
      });
      let params = {};
      if (environment.domain) {
        params = {
          client: environment.domain,
        };
      }
      return this.http.post(this.builder('auth/me'), params, {headers})
        .pipe(
          map((response: any) => {
            if (response.mandante_projects_fleet === null || response.mandante_projects_fleet === undefined) {
              response.mandante_projects_fleet = [];
            }
            this.userLogged = response;
            this.loadingUserLogged = false;
            if (this.userLogged.status === 0) {
              this.borrarToken();
              this.router.navigateByUrl('/login');
              return of([]);
            }
            return response;
          }), catchError((err: any) => {
            console.log(err);
            this.loadingUserLogged = false;
            this.borrarToken();
            this.router.navigateByUrl('/login');
            return of([]);
          })
        );
    } else {
      return of([]);
    }

  }
}
