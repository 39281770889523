import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }

  builder( endpoint: string ): string{
    return `${ environment.backend }/${ endpoint }`;
  }

  builderLoad( endpoint: string ): string{
    return `${ environment.load.url }/${ endpoint }`;
  }
  handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    const errors = error.error.errors;
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    const throwErrorMessage = {
      errorMessage,
      errors,
      status: error.status
    };
    return throwError(throwErrorMessage);
  }
}
