import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'qtyweek'
})
export class QtyweekPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === null){
      return 0;
    } else {
      return value;
    }
  }

}
